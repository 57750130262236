.catalogWrapper {
    display: flex;
    justify-content: space-between;
}
.catalogWrapper >div[class=ant-collapse-content-box]{
    max-width: 250px;
}

.catalogCardsScroll{
    display: flex;
    // grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap;
    gap: 48px 10px;
    justify-items: center;
    margin-left: 10px;
    margin-bottom: 160px;
}

.notFound_catalog{
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 40px;
    margin-left: 50px;
    margin-bottom: 400px;
}

@media (max-width: 1366px) {
    .catalogCardsScroll{
        grid-template-columns: 1fr 1fr;
    }
}
@media (max-width: 768px) {
    .catalogCardsScroll{
        margin-left: 0;
    }
    .notFound_catalog{
        margin-left: 0;
    }
}

.catalogTitle{
    color: #020202;
    font-size: 36px;
    font-weight: 700;

}
.catalog_top_block{
    margin-top: 60px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.catalog_filters_mobile{
    display: flex;
    gap: 10px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .catalogTitle{
        font-size: 20px;
    }
    .catalogCardWrapper{
        
    }
    .catalogCardsScroll{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        justify-content: flex-start;
    }
}

@media (max-width: 768px) {
    .fuckTailwindCatalogLoading{
        display: flex !important;
        
    }
}