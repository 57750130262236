.headerWrapper{
    display: flex;
    align-items: center;
    padding: 14px 0px 25px 0px;
    color: #fff;
    justify-content: space-between;
}

a{
    text-decoration: none;
}

.headerCombine{
    background-color: rgba(2, 2, 2, 1);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.headerHolder{
    padding: 62px 40px;
}

.headerLogo > img{
    height: 30px;
}

.headerCatalog{
    background-color: rgba(34, 34, 34, 1);
    padding: 13px 20px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.headerMenu > div{
    width: 14px;
    height: 2px;
    background-color: #fff;
    border-radius: 2px;
    margin-bottom: 4px;
    margin-top: 4px;
    margin-right: 6px;
}

.headerNavigation{
    color: rgba(132, 132, 132, 1);
    display: flex;
}

.headerNavigation > div{
    margin-right: 45px;
    cursor: pointer;
}

.headerActions{
    display: flex;
}

.headerActions > img{
    margin-left: 16px;
}
.headerActions>a {
    margin-left: 16px;
}

.headerSecondaryWrapper{
    background-color: #fff;
    height: 500px;
    display: flex;
    position: absolute;
    width: 100%;
    z-index: 10000000;
        height: calc(100vh - 122px);
    // bottom: 0;
}

@media (max-width: 768px) {
    .headerSecondaryWrapper{
        flex-direction: column;
        overflow: auto;
        height: auto;
        box-shadow: 0px 0px 24px 0px rgba(82, 99, 250, 0.10);
    }
    .headerSecondaryCategory{
        overflow: hidden;
    }
    .headerSecondaryCategoryItem{
        font-size: 16px;
        padding-bottom: 13px;

    }
    .headerNavigation{
        flex-direction: column;
        padding: 20px 30px 40px 40px;
        border-top: 1px solid #848484;

    }
}

.headerSecondaryCategory {
    overflow: auto;
    padding: 40px 30px 0 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(132, 132, 132, 1);
}
.headerSecondaryCategoryItem {
    padding-bottom: 16px;
}

.headerSecondaryCategoryItem:hover {
    color: rgba(0, 0, 0, 1);
}

.headerSecondarSubcategory{
    width: 80%;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex-wrap: wrap;
}

.headerSecondarSubcategory > div{
    width: 30%;    
}

.headerSecondarSubcategoryItem{
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(132, 132, 132, 1);
    margin-bottom: 10px;
}

.headerSecondarSubSubcategoryItem{
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 10px;
    color: rgba(132, 132, 132, 1);
}

.headerSecondarSubSubcategoryItem:last-child{
    margin-bottom: 20px;
}

.headerSecondarSubSubcategoryItem:hover{
    color: rgba(0, 0, 0, 1);
}

.headerDivider{
    width: 1px;
    height: 500px;
    background-color: rgba(132, 132, 132, 1);
    margin-right: 30px;
}