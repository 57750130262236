@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Passion+One&family=Roboto&display=swap');
*{
  font-family: Roboto !important;
}


body {
  margin: 0;
  /* font-family: "Jost", sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Jost", sans-serif;
  box-sizing: border-box;
}

.PhoneInput {
  margin-top: 4px;
  padding: 8px 12px 8px 12px;
  border: 2px solid black;
  border-radius: 9999px;
}

.PhoneInputInput {
  border: none;
  outline: none;
}

.react-tel-input {
  margin-top: 4px;
  /* padding: 8px 12px 8px 12px; */
  border: 2px solid black;
  border-radius: 9999px;
}

.form-control {
  border: none !important;
  outline: none !important;
  background: transparent !important;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}
.flag-dropdown {
  border: none;
  outline: none;
  background-color: white !important;
  top: 2px !important;
  height: 30px;
  outline: none !important;
}
.react-tel-input .flag-dropdown {
  border: none !important;
  outline: none;
  background-color: white !important;
  outline: none !important;
  border-radius: 70% !important;
  left: 9px;
}

.react-tel-input .form-control {
  border-radius: 25% !important;
  left: 2px !important;
  width: 310px !important ;
}

/*  */

/* apply a natural box layout model to all elements, but allowing components to change */

*,
*:before,
*:after {
  box-sizing: inherit;
}

.timeline {
  text-align: left;
}
.timeline .year {
  display: inline-block;
  font-size: 36px;
  font-weight: bold;
  position: relative;
}
.timeline .year span {
  display: inline-block;
  padding-bottom: 6px;
}
.timeline .year:after {
  content: "";
  display: block;
  /* width: 80%; */
  margin: auto;
  height: 1px;
  background: gray;
}
.timeline .year--end:before {
  content: "";
  display: block;
  /* width: 80%; */
  margin: auto;
  height: 1px;
  background: gray;
}
.timeline .year--end:after {
  content: none;
}
.timeline .days {
  list-style-type: none;
  margin: -50px;
  margin-left: -50px;
  padding: 0;
}
.timeline .days .day {
  /* width: 150%;
  float: left; */
}
.timeline .days .day .events {
  position: relative;
  left: -50px;
  float: left;
  border-right: 1px solid gray;
  padding: 10px;
  text-align: right;
  width: 50%;
}
.timeline .days .day .events:after {
  content: "";
  width: 20%;
  display: inline-block;
  height: 1px;
  background: gray;
  position: absolute;
  right: 0;
}
.timeline .days .day .events .date {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  /* padding: 30px; */
  /* text-align: center; */
  color: gray;
  font-size: 24px;
  white-space: nowrap;
}
.timeline .days .day .events .day__img {
  /* margin-right: -30px; */
  position: relative;
  overflow: hidden;
}

.timeline .days .day:nth-child(odd) .events {
  width: calc(50% + 1px);
  float: right;
  border-right: 0;
  border-left: 1px solid gray;
  text-align: center;
}

.timeline .days .day:nth-child(odd) .events:after {
  right: 50px;
  left: 0;
}
.timeline .days .day:nth-child(odd) .events .date {
  left: auto;
  right: 100%;
  text-align: right;
}
.timeline .days .day:nth-child(odd) .events .day__img {
  margin-right: auto;
}
.timeline .days .day:nth-child(odd) .events .day__img img {
  float: left;
}
.timeline .days .day:nth-child(odd) .events .day__img .caption {
  right: auto;
  left: 0;
}
.events-p {
  width: 9%;
  background: gray;
  margin: 8px 77px 0 120px;
  border-radius: 4px;
}
/*  */

.r-title {
  margin-top: var(--rTitleMarginTop, 0) !important;
  margin-bottom: var(--rTitleMarginBottom, 0) !important;
}

p:not([class]) {
  line-height: var(--cssTypographyLineHeight, 1.78);
  margin-top: var(--cssTypographyBasicMargin, 1em);
  margin-bottom: 0;
}

p:not([class]):first-child {
  margin-top: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
