.basketWrapper{
    margin-top: 10px;
}

.basketCard{
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* gap: 13%; */
    margin-top: 20px;
    margin-bottom: 20px;
}

.basketImg{
    width: 218px;
    height: 206px;
    border-radius: 12px;
    border: 1px solid #848484;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
}

.basketImg img{
    border-radius: 12px;
}

.basketInfo p{
    color: #000;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.basketInfo p:first-child {
    width: 350px;
}

@media (max-width: 1250px) {
    .basketImg {
        width: 150px;
        height: 150px;
    }
        .basketInfo p:first-child{
            width: 100px;
        }
        .basketInfo p{
            font-size: 12px;
        }
        .basketCardCount{
            display: none !important;
        }
        .basketCardPrice{
            font-size: 12px;
        }
        .basketAmount p{
            font-size: 12px;
        }
        .basketPay{
            flex-direction: column;
            align-items: center !important;
        }
                .basketPaymentWrapper>div:first-child {
                    width: 100% !important;
                }
                .basketMobTitle{
                    display: flex !important;
                }
                .basketMobTitle2{
                    font-weight: 600;
                    margin-bottom: 40px;
                    margin-top: 16px;
                }
                .basketMobTitle1{
                    font-weight: 600;
                    font-size: 20px;
                    margin-bottom: 30px;
                }
                .basketTitle{
                    font-size: 24px !important;
                    margin-bottom: 40px !important;
                }
    .basketAmount{
        display: flex !important;
        width: 100% !important;
        flex-direction: column !important;
        align-items: center;
    }
        .basketPaymentInputs {
            display: flex;
            flex-direction: column !important;
        }
}

.basketMobTitle{
    display: none;
}

.basketTable{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.basketTable p:first-child{
    width: 218px;
}

.basketTable p:nth-child(2){
    width: 370px;
}
.basketTable p:nth-child(3) {
    width: 144px;
}

.basketTitle{
    color: #020202;
    font-size: 36px;
    font-weight: 700;
    margin-top: 60px;
    margin-bottom: 60px;
}

.basketPaymentWrapper{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.basketPaymentWrapper > div:first-child{
    width: 64%;
}

.basketPaymentInputs{
    display: flex;
}

.basketPaymentInputsTitle{
    color: #020202;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 30px;
}

.basketPaymentInputsWrapper input{
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 12px;
}

.basketPaymentCardWrapper{
    display: flex;
}

.basketPaymentCard{
    border-radius: 12px;
    border: 1px solid #848484;
    margin-bottom: 30px;
    /* margin-top: 30px; */
    display: flex;
    width: 169px;
    height: 185px;
}

.basketPaymentCard > img{
    border-radius: 12px;
    padding: 25px;
    margin-top: 10px;
    object-fit: contain;
}

.basketPaymentCardInfo{
    /* margin-top: 30px; */
    margin-left: 10px;
}

.basketPaymentCardInfo>div{
    display: flex;
    justify-content: space-between;
}

.basketPaymentCardInfo>div>p:first-child{
    width: 154px;
}

.basketPaymentCards hr{
    margin-bottom: 30px;
}

.basketPaymentTotal{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.basketPaymentTotal p{
    margin: 0;
    margin-bottom: 30px;
}

.basketCode{
    border-bottom: 1px solid #848484 !important ;
    border-radius: 0 !important;
    width: 260px;
    margin-top: 50px;
}

.basketPay{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 40px;
}

.basketAmount{
    margin-top: 50px;
}

.basketAmount > div:first-child{
    display: flex;
    width: 300px;
    justify-content: space-between;
}

.basketAmount p{
    font-size: 24px;
    font-weight: 400;
    margin: 0;
}

.basketAmount>div:last-child{
    /* width: 300px; */
    background-color: #020202;
    color: #fff;
    padding: 13px 88px;
    border-radius: 40px;
    cursor: pointer;
    margin-top: 40px;
}