.reviewTitle{
    color: #FFF;
        font-family: Roboto;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 60px;
}

.reviewWrapper{
    padding-bottom: 160px; 
}

.reviewBlock{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.reviewItem{
    max-width: 675px;
    width: 100%;
    padding: 20px;
    height: 651px;
    border: 1px solid #848484;
    border-radius: 12px;
}

.reviewItemVideo{
    max-width: 636px;
    width: 100%;
    height: 614px;
    border-radius: 12px;
}

@media(max-width: 765px){
.reviewItem{
    height: 377px;
}
.reviewItemVideo{
    height: 350px;
}
.reviewTitle{
    color: #FFF;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 200px;
        margin-bottom: 40px;
}

}
@media(max-width: 1360px) {
    .reviewItem {
        max-width: 650px;
        /* max-width: 100%; */
    }
}
@media(max-width: 1350px) {
    .reviewItem {
        max-width: 100%;
    }
        .reviewItemVideo{
        max-width: 100%;
        }
}