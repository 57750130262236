.headTitle{

    font-size: 38px;
    font-style: normal;
    font-weight: 900; 
    max-width: 600px;
    margin-bottom: 66px;
}

.wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1182px;

}
.input{
    border-radius: 12px;
    border: 1px solid#848484;
    padding: 11px 20px;
    background: #EAEAEA; 
}
.datePicker{
    width: 100%;
    border-radius: 12px;
    border: 1px solid#848484;
    padding: 11px 20px;
    background: #EAEAEA !important; 
     
}
.modal{
    width: 1182px;
    height: 542px; 

    div[class~=ant-modal-content]{
        padding: 30px 0 0 30px;
        background: #EAEAEA; 

    }
}
.form{
    width: 554px;
    .grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
}

.submit{
    border-radius: 2000px;
    background: #222 !important; 
    height: 42px;
}
.checkBox{
    span[class~=ant-checkbox-inner]{
        width: 20px;
        height: 20px; 
        border-radius: 4px;
        background: #EAEAEA; 
    }

}

.submitWrapper{
    display: flex;
    gap: 25px;
    align-items: center;
    margin-top: 30px;
}
.dropdown{
    button[class~="ant-btn-primary"]{
        background: #1677ff !important;
    }
}

@media (max-width: 768px) {
    .form{
        width: auto;
    }
    .modal{
        width: auto !important;
        height: 90vh;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        div[class~=ant-modal-content]{
            padding: 15px;
        }
    }
    .wrapper{
        width: auto;
    }
    .submitWrapper{
        flex-direction: column-reverse;
    }
}