.whyMMTItem{
    width: 447px;
    height: 359px;
    border-radius: 20px;
    padding: 34px;
    display: flex;
    flex-direction: column;
    background-color: #171717;
}

.whyTitleWrapper{
    width: 100%;
    justify-content: flex-start;
}

.whyTitle{
    color: #F1F1F1;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-top: 160px;
        margin-bottom: 60px;
        width: 405px;
}

.whyMMTItem img{
    width: 40px;
    height: 40px;
}

.whyMMTTitle{
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 110px;
    margin-bottom: 30px;
}
.whyMMTDescr{
    color:  #848484;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.whyMMTBlock{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    gap: 20px;
    @media (max-width: 1366px) {
        grid-template-columns: 1fr 1fr;
        
    }
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
        
    }
}

.whyWrapper{
    padding-bottom: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 764px) {
    .whyMMTBlock{
        flex-direction: column;
        align-items: center;
    }

        .whyTitleWrapper {
            display: flex;
        }
    .whyMMTItem{
        width: 100%;
        height: 322px;
    }
    .whyMMTTitle{
        font-size: 16px;
        margin-top: 30px;
    }
    .whyTitle{
        font-size: 24px;
            width: 276px;
    }
        .whyMMTDescr {
            font-size: 14px;
        }
}