.wrapper{
    margin-top: 40px;
}
.images{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 130px;
}

.mainImage{
    max-width: 789px;
    min-width: 500px;
    flex-shrink: 2;
    .mainSourceImage{
        width: 100%;
        height: 556px; 
        border-radius: 20px; 
        object-fit: cover;
    }
    &.empty{
        width: 100%;
        max-width: 100%;
    }
}

.secondaryWrapper{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    flex-basis: 581px;
    flex-grow: 2;
    .secondaryImage{
        height: 273px; 
        border-radius: 20px; 
        object-fit: cover;

    }
}

.item{
    border-radius: 20px; 
    padding: 34px;
    border: 1px solid #848484;
    .headtitle{
        color: #020202;
        margin-bottom: 30px;
        font-size: 36px;
        font-weight: 700; 
    }
    @media (max-width: 768px) {
        // padding: 18px;
        .headtitle {
            font-size: 24px;
        }
        // display: flex;
        // flex-direction: column;
    }
}

.blocks{
    margin-bottom: 160px;
}

.block1{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
}

.block2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
    margin-top: 10px;
        @media (max-width: 768px) {
                display: flex;
                flex-direction: column;
            }
}

.swiper{
    
    span[class~=swiper-pagination-bullet]{
        background-color: #505050 !important;
    }
    span[class~=swiper-pagination-bullet-active]{
        background: #FFF !important;
    }
    div[class~="swiper-button-prev"]{
        width: 34px !important;
        height: 34px !important;
        background-color: #fff;
        border-radius: 50%;
        &::after{
            content: '';
            display: block;
            width: 34px;
            height: 34px;
            background: url('./arrow.svg') center no-repeat;
            background-size: cover;
            transform: rotate(180deg);
            font-family: none;
        }
    }
    div[class~="swiper-button-next"]{
        width: 34px !important;
        height: 34px !important;
        background-color: #fff;
        border-radius: 50%;
        &::after{
            content: '';
            display: block;
            width: 34px;
            height: 34px;
            background: url('./arrow.svg') center no-repeat;
            background-size: cover;
            font-family: none;
        }
    }
}
@media (max-width: 1024px) {
    .secondaryWrapper{
        display: none;
    }
    .images{
        justify-content: center;
    }
}
@media (max-width: 768px) {
    .swiper{
        width: 100%;
    }
    .mainImage{
        max-width: 100%; 
        width: auto !important;
        min-width: 0px !important;
    }
    .mainSourceImage{
        max-width: 100% !important; 
        width: 100% !important;
    }
    .images{
        margin-bottom: 40px;
    }
    .blocks{
        grid-template-columns: 1fr;
    }
}

@media (max-width: 414px) {
    .swiper{
        width: 350px;
        height: 411px; 
    }
    .slide{
        height: 411px;
    }
    .mainImage{
        width: 350px !important;
        min-width: 0px !important;
        height: 100%; 
    }
    .mainSourceImage{
        width: 350px !important;
        height: 100% !important; 
    }
}