.wrapper {
    display: flex;
    flex-direction: column;
    padding: 137px 0 125px;
    width: 100%;
    align-items: center;

    >p {
        margin-bottom: 48px;

        text-align: center;
        font-size: 40px;
        font-weight: 700;
        line-height: 40px;
        color: #fff;
    }
}

.partnersBlock {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px 65px;
}

@media (max-width: 768px) {
    .wrapper {
        padding: 32px 0;

        >p {
            width: auto;
            font-size: 20px;
            line-height: 30px;
            font-weight: 800;
        }
    }

    .partnersBlock {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 20px 65px;

    }
}