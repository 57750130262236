.aboutWrapper{
    background-color: rgba(2, 2, 2, 1);
    padding-bottom: 80px;
    padding-top: 94px;
}

.aboutTitle{
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    color: #fff;
    margin-bottom: 30px;
}
.aboutTitle:target{
    display: none;
}

.aboutMore{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 80px;
    cursor: pointer;
}

.aboutTab{
    padding: 13px 20px;
    border: 1px solid rgba(132, 132, 132, 1);
    border-radius: 30px;
    margin-right: 10px;
    cursor: pointer;
    color: rgba(132, 132, 132, 1);
    &:hover{
            background-color: #fff;
                color: rgba(2, 2, 2, 1);
    transition: background-color 0.5s ease;
    }
}

.aboutTabActive{
    background-color: #fff;
    color: rgba(2, 2, 2, 1);
    transition: background-color 0.5s ease;
}

.aboutTabsWrapper{
    display: flex;
}

.aboutBlock{
    display: flex;
    margin-top: 60px;
    color: rgba(132, 132, 132, 1);
    gap: 15px;
    justify-content: space-between;
    img{
        object-fit: cover;
        object-position: top;
    }
}



.aboutBlock p{
    max-width: 623px;
}

.aboutBlockDesc{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.aboutBlockDesc > img{
    width: 299px;
    height: 230px;
    margin-top: 50px;
}

.aboutBlock > img{
    width: 599px;
    height: 599px;
    border-radius: 20px
}

@media (max-width: 768px) {
    .aboutBlock{
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        >img{
            width: 100%;
            object-fit: cover;
            margin-top: 50px;
        }
    }
}
@media (max-width: 1360px) {
.aboutEmployeeBlock {
        gap: 6px !important;
    }
}

.aboutTeamWrapper{
    display: flex;
    justify-content: space-between;
    color: rgba(132, 132, 132, 1);
    gap: 15px;
    margin-top: 60px;
    img{
        object-fit: cover;
    }
    .aboutTeamBlock img {
        border-radius: 20px;
    }
}

.aboutTeamBlock{
    width: 600px;
}

// .aboutTeamWrapper p{
//     width: 50%;
// }



.aboutTeamBlock > img{
    width: 600px;
    height: 600px;
}


.aboutTeamBlockTitle{
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    color: #fff;
    margin-top: 40px;
}

.aboutTeamBlockDesc{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 30px;
}


.aboutEmployeeBlock{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    color: rgba(132, 132, 132, 1);
    margin-top: 100px;
}

.aboutEmployeeItem{
    display: flex;
    flex-direction: column;
}

.aboutEmployeeItemTitle{
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    margin-top: 40px;
}

.aboutEmployeeItemDesc{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 10px;
}

.aboutEmployeeItem > img{
    width: 308px;
    height: 370px;
    border-radius: 20px;
}

.readMoreAbout{
    font-size: 16px; 
    color: #848484;
    text-decoration: underline; 
    margin-top: 30px;
    cursor: pointer;
    @media (max-width: 768px) {
        font-size: 12px;
    }
}
@media (max-width: 960px) {
    .missionImg{
        width: 100% !important;
    }
}
@media (max-width: 768px) {
    
    .aboutTeamWrapper{
        flex-direction: column;
        .aboutTeamBlock{
            width: 100%;
        }

        .aboutTeamBlock > img{
            width: 100%;
            height: auto; 
            margin-top: 60px;
        }
        >p{
            width: 100%;
        }
        .aboutTeamBlockDesc{
            width: 100%;
        }
        
    }
    .aboutEmployeeBlock{
        margin-top: 30px;
        justify-content: center;

    }
    .aboutTabsWrapper{
        flex-wrap: wrap;
        row-gap: 10px;
        .aboutTab{
            padding: 6px 10px; 
            font-size: 14px; 
        }
    }
}
.gendirPhoto img{
    width: 599px;
    height: 599px;
    object-fit: cover;
    border-radius: 20px; 
    @media (max-width: 960px) {
        width: 100%;
        height: auto;
    }
}

.about_faq{
    height: 430px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 20px;
    background: #FFF; 
    margin-top: 120px;
    padding: 15px;
}

@media (max-width: 768px) {
    .gendirPhoto img{
        width: 100% !important;
        height: auto;
    }
    .aboutTeamBlock.gendirPhoto{
        width: 100%;
    }
    .aboutBlock.gendirBlock{
        flex-direction: column-reverse;
    }
    .about_faq{
        height: auto;
        padding:  60px 15px;
    }
}

.genDirdesc{
    max-width: 623px; 
    >p{
        width: 100%;
    }
}

.aboutContentWrapper{
    padding: 34px;
    background-color: #171717;
    border-radius: 20px;
}