.footerWrapper{
    background: rgba(2, 2, 2, 1);
    color: #fff;
    a{
    color: rgba(132, 132, 132, 1);
    text-decoration: none;
    }
}

.footerSocials{
    display: flex;
    width: 208px;
    justify-content: space-between;
    
    a:hover{
        rect{
            fill: rgba(241, 241, 241, 1);
        }
        path{
            fill: rgba(23, 23, 23, 1);
        }
    }

}

.footerHead{
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
}

.footerBoldText{
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 20px;
}

.footerText{
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: rgba(132, 132, 132, 1);
    cursor: pointer;
}

.footerBody{
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
}
.footer_links_oferta{
    text-align: right;
    padding-bottom: 50px;
}
.footer_oferta{
    margin-top: 170px;
    padding-bottom: 10px;
}
.footer_bottom_documents{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}
@media (max-width: 768px) {
    .footerBody, .footerHead{
        flex-direction: column;
        gap: 60px;
    }
    .logo_footer_head{
        width: 75px;
        height: 28.602px; 
    }
    .footer_top_block{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .footer_links_oferta{
        text-align: left;
        margin-bottom: 50px;
    }
    .footer_oferta{
        margin-top: 0;
        padding-bottom: 0;
        margin-bottom: 10px;
    }
    .footer_bottom_documents{
        align-items: flex-start;
    }
}

.footer_order_call_button{
    display: inline-flex;
    padding: 13px 20px;
    align-items: center; 
    border-radius: 2000px;
    background: #222; 
}


