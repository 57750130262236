.companiesWrapper{
    margin: 160px 0;
}
.companeisBlock{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
}
.companiesTitle{
    color: #020202;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 60px;
    @media(max-width: 764px){
        font-size: 24px;
        width: 250px;
    }
}
.companiesItem{
    border-radius: 20px;
    border: 1px solid #848484;
    max-width: 447px;
    max-height: 192px; 
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 56px 15px;
    >img{
        max-width: 167px;
        max-height: 54.609px; 
        min-width: 117px;
        object-fit: cover;
    }
    @media (max-width: 768px) {
        padding: 29px 10px;
    }
}