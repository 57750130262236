.background{
    height: 104px;
    background: #020202;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
}
.logo{
    width: 58px;
    height: 22.119px; 
}

.burger{
    width: 24px;
    height: 24px;
}

.headerActions{
    display: flex;
}

.mobheaderSearch{
    position: relative;
}
.searchImg{
    position: absolute;
    top: 7px;
    left: 5px;
}