.container{
    margin-top: 60px;
    margin-bottom: 160px;
    .headtitle{
        color: #020202;
        font-size: 48px;
        font-weight: 700; 
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(2, 2, 2, 0.40);
        
    }
    .lookbook{
        gap: 10px;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        .lookbook1{
            display: flex;
            justify-content: space-between;
            gap: 20px;
            img{
                flex-shrink: 2;
                
            }
            
        }
        .lookbok2{
            display: flex;
            gap: 10px;
            align-items: flex-end;

        }
        .lookbook4{
            .small{
                align-self: flex-end;
            }
        }
        .lookbok5{
            display: flex;
            justify-content: space-between;
            gap: 10px;
        }
    }
    .smallestImg{
        display: flex;
        gap: 10px;
    }
    img{
        border-radius: 20px;
    }
    .big{
        width: 675px; 
        height: 831px; 
        object-fit: cover;
    }
    .small{
        width: 333px;
        height: 388px; 
        object-fit: cover;
    }
    .text{
        max-width: 322px;
    }

    .faqForm{
        width: 100%;
        height: 430px; 
        border-radius: 20px;
        background: #F4F4F4; 
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        margin-top: 160px;
    }
    
}

@media (max-width: 1024px) {
    .container .faqForm{
        height: auto;
        padding:  60px 15px;
    }
    .lookbook{
        .lookbook1{
            flex-direction: column;
            justify-content: center;
        }
        .smallestImg{
            flex-direction: column;
            justify-content: center;
        }
        .big{
            width: 100%; 
            height: 355px; 
            object-fit: cover;
        }
        .small{
            width: 100%;
            height: 355px; 
        }
        .text{
            max-width: 100%;
        }
    }

    
}