.ant-collapse-item{
    width: 200px;
}

.catalogFilters{
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
}

.catalogFiltersReset{
    color: #848484;
    font-size: 14px;
    font-weight: 400;
    text-decoration-line: underline;
    cursor: pointer;
}

.catalogFiltersApply{
    font-size: 14px;
    font-weight: 400;
    text-decoration-line: underline;
    cursor: pointer;
}

.filters_mobile_wrapper{
    position: fixed;
    background-color: #fff;
    z-index: 9000000;
    
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    overflow: auto;
    padding: 0 15px;
    @media (max-width: 768px) {
        left: 30%;
        
    }
    @media (max-width: 500px) {
        left: 0 !important;

        
    }
}
.background{
    content: '';
    position: fixed;
    background: rgba(0, 0, 0, 0.40);
    z-index: 1;
    inset: 0;
}
.filters_mobile_top_modal{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    padding-bottom: 30px;
    border-bottom: 1px solid #848484;
    >p{
        font-size: 24px; 
        font-weight: 500; 
    }
}
.filters_mobile_close_modal{
    cursor: pointer;
}
@media (max-width: 768px) {
    .filtersWrapper{
        margin-top: 20px;
    }
    .filtersWrapper div[class~=ant-collapse-header]{
        padding-left: 0 !important;
    }
    .catalogFiltersReset, .catalogFiltersApply{
        font-size: 18px;
    }
    .catalogFilters{
        justify-content: flex-end;
        gap: 30px;

    }
}

.customFilters span[class=ant-collapse-header-text]{
    color: #848484;
    font-size: 16px;
}

.customFilters div[class=ant-collapse-header] {
    padding: 0 !important;
    margin-bottom: 16px;
}

.customFilters div[class=ant-collapse-expand-icon] {
    padding: 0 !important;
}
