.popularCards{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 15px;
}

.catalogCardWrapper1{
    flex-basis: 430px !important;
    cursor: pointer;
}

.catalogCardWrapper1 > div:first-child{
    border: 1px solid #848484;
    border-radius: 20px;
    margin-bottom: 20px;
    padding: 100px 20px;

}

.catalogCardWrapper1 img{
    border-radius: 20px;
}

@media (max-width: 764px) {
    .catalogCardWrapper{
        width: 153px !important;
        height: 250px !important;
    }
        .popButtons{
            display: none !important;
        }
                .catalogCardWrapper1>div:first-child {
                    padding: unset;
                }
                .catalogCardTitle{
                    font-size: 16px !important;
                }

}

.popWrapper{
    margin-top: 100px;
    margin-bottom: 60px;
}



.popTitleButtons{
    display: flex;
    justify-content: space-between;
}

.popButtons{
    display: flex;
}

.popButtons div{
    width: 34px;
    height: 34px;
    border: 1px solid #848484;
    color: #848484;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    margin-right: 8px;
}