:root {
    --swiper-navigation-size: 44px;
    /*
    --swiper-navigation-top-offset: 50%;
    --swiper-navigation-sides-offset: 10px;
    --swiper-navigation-color: var(--swiper-theme-color);
    */
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
  }
  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  .swiper-button-prev.swiper-button-hidden,
  .swiper-button-next.swiper-button-hidden {
    opacity: 0;
    cursor: auto;
    pointer-events: none;
  }
  .swiper-navigation-disabled .swiper-button-prev,
  .swiper-navigation-disabled .swiper-button-next {
    display: none !important;
  }
  .swiper-button-prev svg,
  .swiper-button-next svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform-origin: center;
  }
  .swiper-rtl .swiper-button-prev svg,
  .swiper-rtl .swiper-button-next svg {
    transform: rotate(180deg);
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    left: var(--swiper-navigation-sides-offset, 10px);
    right: auto;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 10px);
    left: auto;
  }
  .swiper-button-lock {
    display: none;
  }
  /* Navigation font start */

  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    right: var(--swiper-navigation-sides-offset, 10px);
    left: auto;
  }
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: 'next';
  }
  /* Navigation font end */
  