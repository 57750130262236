.container{
    width: 1360px;
    margin: auto;
}

@media (max-width: 1360px) {
    .container{
        width: 100%;
        padding: 0 20px;
    }
    
}