.container{
    margin: 60px auto 160px auto;
}
.headTitle{
    font-size: 38px;
    font-style: normal;
    font-weight: 700; 
    margin-bottom: 60px;
}
.imageBlock{
    border-radius: 20px;
    background: #F2F2F2; 
    width: 100%;
    height: 542px; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0 40px 40px;
    margin-bottom: 160px;
    .items{
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .item{
            color: #848484;
            padding: 10px 0;
            border-bottom: 1px solid #848484;
            font-size: 16px;
        }
    }
    .reducer02{
        margin-top: 50px;
        >p{
            font-size: 14px; 
            margin-bottom: 20px;
        }
        .icons{
            display: flex;
            align-items: flex-end;
            gap: 10px;
        }
    }
}

.formWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.form{
    width: 605px;
    .input{
        border-radius: 12px;
        border: 1px solid#848484;
        padding: 11px 20px;
    }
    
}

.date{
    margin-top: 20px;
    margin-bottom: 10px;
    >p{
        color: #020202;
        font-size: 16px; 
        margin-bottom: 10px;
    }
    
}
.datePicker{
    width: 100%;
    border-radius: 12px;
    border: 1px solid#848484;
    padding: 11px 20px;
    
}
.dropdown{
    button[class~="ant-btn-primary"]{
        background: #1677ff !important;
    }
}

.select{
    height: 42px !important;

    div[class~=ant-select-selector]{
        border-radius: 12px !important;
        padding: 11px 20px !important;
        height: 42px !important;
        border: 1px solid#848484 !important;

    }
}

.bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    margin-top: 40px;
}

.submit.submit{
    border-radius: 2000px;
    background: #222 !important; 
    height: 42px;
}

@media (max-width: 768px) {
    .form{
        width: 100%;
    }
    .bottom{
        flex-direction: column-reverse;
    }
    .imageBlock{
    padding: 15px 0 15px 15px;

        >img{
            display: none;
        }
    }
    .headTitle{
        font-size: 20px;
    }
    .item{
        font-size: 14px;
    }
    .items{
        padding-right: 15px;
    }
}