.ideaBlockTitle {
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    color: #fff;
    margin-bottom: 60px;
    width: 466px;
    margin-top: 160px;
}

.ideaItemTitle{
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;  
    padding-top: 35px;
    margin-left: 35px;
}

.ideaItem{
    background-color: violet;
    height: 287px;
    border-radius: 20px;
    background-size: cover !important;
}


.ideaBlock{
    flex-grow: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    cursor: pointer;
}

.ideaWrapperBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.ideaBlockBig .ideaItem{
    width: 447px;
    height: 584px;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-row-end: 5;
}

@media (max-width: 768px) {

    .ideaBlockTitle{
        font-size: 24px;
        width: 276px;
    }

    .ideaBlockBig{
        width: 100%;
    }
    .ideaWrapperBlock{
        flex-direction: column-reverse;
            p {
                    font-size: 16px;
                }
    }
    .ideaItem{
        width: 100%;
        height: 188px;
    }
    
    .ideaBlockBig .ideaItem{
        width: 100%;
        flex-grow: 2;
    }
    .ideaBlock{
        width: 100%;
        grid-template-columns: 1fr 1fr;
        margin-top: 10px;
    }
        .ideaItemTitle{

            margin-left: 10px !important;
            padding-top: 10px !important;
        }
}