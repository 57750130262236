.headTitle{
    color: #000;
    font-size: 38px;
    font-style: normal;
    font-weight: 700; 
    margin-bottom: 40px;
}
.inputs{
    display: flex;
    gap: 12px;
    button[class~=ant-btn]{
        border-radius: 2000px !important;
        background: #020202 !important;
        height: 42px;
    }
    div:not(:last-child){
        flex-grow: 2;
    }
}
.input{
    border-radius: 20px;
    border: 1px solid#848484;
    padding: 8px 20px;
    height: 42px;
    
}

.bottom{
    button[class~=ant-btn]{
        border-radius: 2000px !important;
        background: #020202 !important;
        height: 42px;
    }
}

@media (max-width: 768px) {
    .inputs{
        flex-direction: column;
    }
    .bottom{
        display: flex;
        flex-direction: column-reverse;
        button[class~=ant-btn]{
            width: 100%;
        }
    }
    .headTitle{
        font-size: 28px;
    }
}