.productWrapper{
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    margin-bottom: 160px;
}

.productImgWrapper{
    width: 50%;
    display: flex;
}

.productMainImg{
    height: 820px;
    border-radius: 20px;
    border: 1px solid #848484;
    display: flex;
    align-items: center;
}

.productInfo{
    width: 48%;
    display: flex;
    flex-direction: column;
}

.productTitle{
    color: #020202;
    font-size: 36px;
    font-weight: 700;
    padding-right: 20px;
}

.productInfoBlock{
    display: flex;
    // align-items: center;
    margin-bottom: 20px;
}

.productPrice{
    width: 270px;
    color: #020202;
    font-size: 36px;
    font-weight: 700;
}

.productDescription{
    color: #848484;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: 410px;
    margin-bottom: 30px;
}

.productColor{
    color: #020202;
    font-size: 24px;
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 20px;
}

.productColorBlock{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.productColorButton{
    padding: 5px 15px;
    border: 1px solid #848484;
    color: #848484;
    border-radius: 20px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
}

.productColorButtonActive{
    background-color: #020202;
    border: 1px solid #020202;
    color: #fff;
}

.productAdd{
    width: 100%;
    cursor: pointer;
    background-color: #020202;
    color: #fff;
    padding:  13px 0;
    border-radius: 40px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 60px;
}

.productSecondaryImg{
    display: flex;
    flex-direction: column;
}

.productSecondaryImg div{
    border: 1px solid #848484;
    margin-bottom: 10px;
    border-radius: 20px;
    margin-right: 10px;
}

.productSecondaryImg img{
    width: 104px;
    height: 111px;
    object-fit: contain;
    border-radius: 20px;
}

.ant-collapse-header-text{
    color: #020202;
    font-size: 24px;
    font-weight: 500;
}

.ant-collapse-header{
    width: 250px;
}

.ant-collapse-expand-icon{
    padding-top: 20px;
}

.ant-collapse-item{
    width: 100%;
}

@media (max-width: 768px) {
    .productWrapper{
        flex-direction: column;
    }
    .productInfo{
        width: 100%;
        margin-top: 40px;
    }
    .productInfoBlock{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 40px;
        .productTitle, .productPrice{
            color: #020202;
            font-size: 20px;
            font-style: normal;
            line-height: normal; 
            width: auto;
        }
        .productTitle{
            font-weight: 700;

        }
        .productPrice{
            min-width: 50px;
        }
    }
    .productDescription{
        width: 100%;
    }
    .popularCards{
        display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
            justify-content: flex-start;
            img{
                height: 195px !important;
                object-fit: contain;
            }
            .catalogCardWrapper1{
                max-width: 163px !important;
            }
    }
}

