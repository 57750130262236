.wrapper{
    margin-top: 40px;
}
.images{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 80px;
}

.mainImage{
    max-width: 100%;
    height: 556px; 
    flex-grow: 2;
    .mainSourceImage{
        width: 100%;
        height: 556px; 
        border-radius: 20px; 
        object-fit: cover;
    }
}

.item{
    border-radius: 20px; 
    padding: 34px;
    border: 1px solid #848484;
    .headtitle{
        color: #020202;
        margin-bottom: 30px;
        font-size: 36px;
        font-weight: 700; 
    }
}

.blocks{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
    grid-template-rows: 1fr 2fr;
    margin-bottom: 160px;
}

.blogContent{
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.blogText{
    width: 40%;
    @media (max-width: 768px) {
        width: 100%;
    }
    .headTitleTitleBlog{
        // color: #020202;
        // font-size: 24px;
        // font-weight: 500; 
        color: #020202;
            font-family: Roboto;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 108.5%;
            /* 52.08px */
        margin-bottom: 30px;
        @media (max-width:768px) {
                font-size: 24px;
        }
    }
    .briefAbout{
        color: #535252;
        font-size: 20px;
        font-weight: 500; 
        margin-bottom: 30px;
    }
    .date{
        margin-bottom: 50px;
    }
}

.swiper{
    
    span[class~=swiper-pagination-bullet]{
        background-color: #505050 !important;
    }
    span[class~=swiper-pagination-bullet-active]{
        background: #FFF !important;
    }
    div[class~="swiper-button-prev"]{
        width: 34px !important;
        height: 34px !important;
        background-color: #fff;
        border-radius: 50%;
        &::after{
            content: '';
            display: block;
            width: 34px;
            height: 34px;
            background: url('./arrow.svg') center no-repeat;
            background-size: cover;
            transform: rotate(180deg);
            font-family: none;
        }
    }
    div[class~="swiper-button-next"]{
        width: 34px !important;
        height: 34px !important;
        background-color: #fff;
        border-radius: 50%;
        &::after{
            content: '';
            display: block;
            width: 34px;
            height: 34px;
            background: url('./arrow.svg') center no-repeat;
            background-size: cover;
            font-family: none;
        }
    }
}

@media (max-width: 768px) {
    .secondaryWrapper{
        display: none;
    }
    .mainImage{
        width: 100%;
        height: 414px;
    }
    .mainSourceImage{
        height: 414px !important;
    }
    .images{
        margin-bottom: 40px;
    }
    .blocks{
        grid-template-columns: 1fr;
    }
}

.simular{
    width: 447px;
    text-align: left;
    img{
        height: 327px;
        border-radius: 20px;
        object-fit: cover;
    }
        @media (max-width: 768px) {
            width: 100%;
        }
}
.simularTitle{
color: #020202;
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 108.5%;
    margin-top: 30px;
    /* 26.04px */
}
.simularDesc{
color: #000;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 136%;
    margin-top: 20px;
    /* 21.76px */
}
.simularMore{
color: var(--unnamed, #848484);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    margin-bottom: 60px;
    margin-top: 20px;
}
.more{
    color: #020202;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 108.5%;
        /* 26.04px */
        margin-bottom: 30px;
        cursor: pointer;
}