.specialItem{
    width: 447px;
    height: 470px;
    border-radius: 20px;
    border: 1px solid;
    padding: 34px
}

.specialWrapper{
    margin-top: 160px;
    padding-bottom: 60px;
}
.specialBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.specialTitle{
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    color: #fff;
    margin-bottom: 60px;
}

.specialItemTitle{
    color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 24px;
}

.specialItemAction{
    cursor: pointer;
    text-decoration: underline;
    color: #848484;
    margin-top: 24px;
}

.specialItemAction:hover{
    color: rgba(0, 0, 0, 1);
}

@media (max-width: 764px){
    .specialBlock {
        flex-direction: column;
    }
.specialItem {
    width: 355px;
    height: 406px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.specialTitle{
            font-size: 24px;
                width: 276px;
}
.specialItemDesc{
    display: none;
}
.specialItemAction{
    background-color: #020202;
    text-decoration: none;
    color: #fff;
    display: flex;
    justify-content: center;
    border-radius: 40px;
    padding: 13px;
    box-shadow: 10px 10px 20px 20px rgb(255, 255, 255, 0.4);
}
}