

@media(max-width: 764px){
.profileBlock{
    flex-direction: column !important;
}
.profileBlock > div:last-child{
    margin-top: 20px;
}
.profileInputs{
    width: 80% !important;
}
}

.profileInputs{
    width: 45%;
}