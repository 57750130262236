.contactsBlock{
    background-color: rgba(2, 2, 2, 1);
}

.contactsBlockWrapper{
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.contactsMap{
    width: 736px;
    height: 876px;
    border: 1px solid rgba(132, 132, 132, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 15px;

    >iframe{
        width: 100%;
        height: 100%;
    }
}

.contactsBlockTitle{
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    color: #fff;
    padding-top: 60px;
    padding-bottom: 60px;
}

.contactsBlockItems{
    display: grid;
    flex-grow: 2;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.contactsItem{
    display: flex;
    flex-direction: column;
    background-color: rgba(2, 2, 2, 1);
    height: 433px;
    border: 1px solid rgba(132, 132, 132, 1);
    color: rgba(132, 132, 132, 1);
    border-radius: 20px;
    padding: 34px;
    &:hover{
        border: 1px solid #F1F1F1;
        p{ 
            color: #F1F1F1;
        }
                rect{
                    stroke:#F1F1F1 ;
                }
                path{
                    fill: #F1F1F1;
                }
    }

}

.contactsItem > img{
    width: 50px;
}

.contactsItemTitle{
    display: flex;
    margin-top: 280px;
}

.contactsItemTitle > img {
    width: 34px;
    height: 34px;
}

.contactsItemTitle > p {
    font-size: 24px;
    font-weight: 500;
    line-height: 33px;
    margin-top: 6px;
}

@media (max-width: 768px) {
    .contactsBlockWrapper{
        flex-direction: column;
    }
    .contactsBlockItems{
        width: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 10px;
        margin-bottom: 10px;
    }
    .contactsItem{
        width: 100%;
        height: 212px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px;
        
        .contactsItemTitle {
            margin-top: 0;
            display: flex;
            align-items: center;
            p {
                font-size: 20px; 
            }
            >img{
                width: 16px;
                height: 16px; 
                margin-right: 10px;
            }
        }
    }

    .contactsMap{
        width: 100%;
        height: 379px;
        display: flex;
        align-items: center;
        justify-content: center;
       
    }
}