.mainSlider {

    span[class~=swiper-pagination-bullet] {
        background-color: #505050 !important;
    }

    span[class~=swiper-pagination-bullet-active] {
        background: #FFF !important;
    }

    div[class~="swiper-button-prev"] {
        width: 34px !important;
        height: 34px !important;
        background-color: #fff;
        border-radius: 50%;

        &::after {
            content: '';
            display: block;
            width: 34px;
            height: 34px;
            background: url('./arrow.svg') center no-repeat;
            background-size: cover;
            transform: rotate(180deg);
            font-family: none;
        }
        @media (max-width: 768px) {
            display: none;
        }
    }

    div[class~="swiper-button-next"] {
        width: 34px !important;
        height: 34px !important;
        background-color: #fff;
        border-radius: 50%;

        &::after {
            content: '';
            display: block;
            width: 34px;
            height: 34px;
            background: url('./arrow.svg') center no-repeat;
            background-size: cover;
            font-family: none;
        }
                                @media (max-width: 768px) {
                                    display: none;
                                }
    }
}

.main{
    color: #fff;
    margin-bottom: 60px;
}

.slide{
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #131313;
    // background-color: red;
    margin-top: 60px;
    padding-bottom: 38px;
    border-radius: 20px;
    padding: 15px 60px;
}

.info{
    display: flex;
    flex-direction: column;
    margin-top: 15%;
}

.title1{
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
    width: 570px;
    color: #000;
}

.descr1{
    font-size: 16px;
    font-weight: 400;
    width: 469px;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #000;
}

.actionButtons1{
    display: flex;
}

.actionButtons1 div{
    padding: 13px 20px;
    border-radius: 40px;
    cursor: pointer;

}

.actionButtons1 div:first-child {
    background-color: #000;
    color: #fff;
    margin-right: 10px;
    &:hover{
        background-color: #242424;
    }
}

.actionButtons1 div:last-child{
    opacity: 0.6;
    border: 1px solid #000;
    padding: 0;
    display: flex;
    align-items: center;
    color: #000;
        &:hover {
            opacity: unset;
        }
}

.actionButtons1 a{
    padding: 13px 20px;
}

.actionButtons1 a:hover{
    color: #000 !important;
}

@media (max-width: 764px){
    .slide{
        flex-direction: column-reverse;
        height: 650px;
    }
        .title1{
            width: 276px;
            font-size: 24px;
        }
                .actionButtons1 div:last-child {
                    display: none;
                }
                .actionButtons1 div:first-child{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-bottom: 40px;
                }
}

// ==========

.title2 {
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
    width: 570px;
    color: #fff;
}

.title3{
        font-size: 36px;
            font-weight: 700;
            line-height: normal;
            width: 643px;
            color: #fff;
}
.title4 {
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
    width: 676px;
    color: #fff;
}
.title5 {
    font-size: 36px;
    font-weight: 700;
    line-height: normal;
    width: 457px;
    color: #000;
}
.descr2 {
    font-size: 16px;
    font-weight: 400;
    width: 469px;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #fff;
}

.descr3 {
    font-size: 16px;
    font-weight: 400;
    width: 467px;
    margin-top: 30px;
    margin-bottom: 30px;
    color: #000;
}
.actionButtons2 {
    display: flex;
}

.actionButtons2 div {
    padding: 13px 20px;
    border-radius: 40px;
    cursor: pointer;
}

.actionButtons2 div:first-child {
    background-color: #fff;
    color: #000;
    margin-right: 10px;
                &:hover {
                        opacity: 0.6;
                    }
}

.actionButtons2 div:last-child {
    opacity: 0.6;
    border: 1px solid #fff;
    padding: 0;
    display: flex;
    align-items: center;
    color: #fff;
            &:hover {
                    opacity: unset;
                }
}

.actionButtons2 a {
    padding: 13px 20px;
}

@media (max-width: 764px) {
    .slide {
        flex-direction: column-reverse;
        height: 650px;
    }

    .title2 {
        width: 276px;
        color: #fff;
        font-size: 24px;
    }
                .title3 {
                    width: 276px;
                    color: #fff;
                    font-size: 24px;
                }
                                        .title4 {
                                            width: 276px;
                                            color: #fff;
                                            font-size: 24px;
                                        }
                                                                                .title5 {
                                                                                    width: 276px;
                                                                                    color: #000;
                                                                                    font-size: 24px;
                                                                                }
    .actionButtons2 div:last-child {
        display: none;
    }

    .actionButtons2 div:first-child {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }
}

// ============


.mobSlide1{
    display: flex;
    flex-direction: column;
    height: 650px;
    border-radius: 12px;
    justify-content: flex-end;
    padding: 10px;
     div{
        margin-bottom: 64px;
        margin-top: 30px;
    }
    & p:first-child{
        color: #020202;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 100.187%; /* 24.045px */
    }
    .descr1{
color: #020202 ;
font-size: 16px;
font-style: normal;
font-weight: 400;
width: 102%;
margin-top: 20px !important;
margin-bottom: 0px !important;
line-height: 136%; /* 21.76px */
    }
}

.mobButton1{
    background-color: #fff;
    color: #000;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
}
.mobButton2{
    background-color: #000;
    color: #fff;
    padding: 13px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
}

.mobSlide2{
    display: flex;
    flex-direction: column;
    height: 650px;
    border-radius: 12px;
    justify-content: flex-start;
    padding: 10px;
    div p:first-child{
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        margin-top: 20px;
        line-height: normal;
    }
    div p:last-child {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 136%;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    & div:last-child{
        margin-bottom: 64px;
    }
}


.mobSlide3 {
    display: flex;
    flex-direction: column;
    height: 650px;
    border-radius: 12px;
    justify-content: space-between;
    padding: 10px;

    div p:first-child {
        color: #FFF;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 20px;
    }

    div p:last-child {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 136%;
        margin-bottom: 30px;
        margin-top: 30px;
        width: 102%;
    }

    & div:last-child {
        margin-bottom: 64px;
    }
}