.qestionTitle{
    color: #FFF;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-top: 160px;
        margin-bottom: 60px;
}

#faq{
    padding-bottom: 160px;
}

.faqCollapse{
    position: relative;
}

.faqCollapse div[class=ant-collapse-header]{
    width: 100% !important;
    padding: 44px !important;
}

.faqCollapse span[class=ant-collapse-header-text] {
    color: #fff !important;
}

.faqCollapse span[class=ant-collapse-header-text] p{
    margin-left: 80px !important;
}

.faqCollapse span[class=ant-collapse-header-text] p:last-child{
    font-size: 16px;
    color: #848484;
    text-decoration: underline;
    margin: 0;
}

.faqCollapse svg{
    color: #fff !important;
}
.faqCollapse div[class=ant-collapse-content-box]{
    color: #fff !important;
    background-color: #020202;
    border-radius: 0 0 8px 8px;
}
.faqCollapse div[class~=ant-collapse-item] {
    margin-bottom: 10px !important;
    border: 1px solid #848484;
    border-radius: 10px !important;
}

.faqCollapse div[class~=ant-collapse]{
    border: 0 !important;
}

.faqCollapse div[class=ant-collapse-expand-icon]{
    margin-top: 12px;
}

.faqCollapse div[class~=ant-collapse-content]{
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.faqSubtitle{
    font-size: 16px;
}

@media (max-width: 768px) {
    .qestionTitle{
                font-size: 24px;
                    width: 276px;
    }
        .faqCollapse span[class=ant-collapse-header-text] {
            font-size: 14px !important;
            
        }
                .faqCollapse span[class=ant-collapse-header-text] p {
                    margin-left: 40px !important;
                }
                                .faqCollapse span[class=ant-collapse-header-text] p:last-child {
                                    display: none;
                                }
                                .faqCollapse div[class=ant-collapse-extra]{
                                    margin-top: 10px;
                                    }
}


.faqIcon{
    background-color: #3E3E3E;
    border-radius: 20px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
}
.faqIcon:hover {
    background-color: #fff;
}
.faqIcon:hover path {
    fill:rgba(14, 14, 14, 1);
}

.faqCollapse div[class=ant-collapse-extra]{
    position: absolute;
    color: #fff;
    bottom: 65px;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}