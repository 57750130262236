.pass-wrapper {
  position: relative;
  display: flex;
  margin-bottom: 14px;
}

i {
  position: absolute;
  color: white;
  top: 24%;
  right: 8%;
}
i:hover {
  color: #00fcb6;
  cursor: pointer;
}
