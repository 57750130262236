.blogItem{
    margin: 0 auto;
    >img{
        width: 675px;
        height: 429px; 
        object-fit: cover;
        margin-bottom: 30px;
        border-radius: 20px; 
    }
    .blogItemTitle{
        color: #020202;
        font-size: 24px;
        font-weight: 500; 
        margin-bottom: 20px;
    }
    .created_at{
        color: #848484;
        font-size: 14px; 
        margin-bottom: 30px;
    }
    .bottom{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .brief{
            max-width: 333px;
        }
        a{
            color: #848484;
            text-decoration: underline;
            font-size: 16px; 
        }
    }
}

.blog_item_tab_selected{
    background: #020202;
    color: #fff;
}

.blogBlockTitle{
    margin-bottom: 0px;
}
.blogBlock{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 59px;
    column-gap: 10px;
}

@media (max-width: 1024px) {
    .blogBlock{
        grid-template-columns: 1fr;
    }
}