.porfolioItem{
    margin: 0 auto;
}

a{
    color: unset;
    text-decoration: none;
}
a:hover{
    text-decoration: none;
        color: unset;
}
.porfolioItem > img{
    width: 675px;
    height: 429px !important;
    border-radius: 20px;
    object-fit: cover;
}

.porfolioItemTitle{
    font-size: 24px;
    font-weight: 500;
    line-height: 33px;
    margin-top: 40px;
}

.porfolioBlockTitle{
    font-size: 36px;
    font-weight: 700;
    line-height: 42px;
    margin-top: 60px;
    margin-bottom: 40px;
    color: black;
}

.porfolioBlockDesc{
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    width: 75%;
    color: rgba(132, 132, 132, 1);
}

.portfolioBlock{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    row-gap: 60px;
}

.portfolio_block_tab{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 60px;
    .portfolio_item_tab{
        display: inline-flex;
        padding: 13px 20px;
        align-items: center;
        gap: 6px; 
        border-radius: 40px;
        border: 1px solid var(--unnamed, #848484);
        cursor: pointer;
        user-select: none;
        &:hover{
            background-color: #020202;
            border: 1px solid #020202;
            color: #fff;
        }
    }
}

.portfolio_item_link{
    font-size: 16px; 
    text-decoration: underline;
    color: #848484;
    margin-top: 30px;
    &:hover{
        color: #848484;
    }
}

.portfolio_getMore{
    display: flex;
    padding: 13px 20px;
    align-items: center;
    gap: 6px;
    border-radius: 2000px;
    border: 1px solid var(--unnamed, #848484);
    margin-top: 60px;
    align-self: center;
    cursor: pointer;
}

.portfolio_wrapper{
    display: flex;
    flex-direction: column;
    margin-bottom: 262px;
}