.catalogCardWrapper{
    display: flex;
    flex-direction: column;
    max-width: 357px;
    .catalogCardImg{
        border-radius: 20px;
        border: 1px solid #848484;
        height: 483px; 
        object-fit: contain;
        margin-bottom: 30px;
        @media (max-width: 768px) {
            height: 195px; 
        }
    }
    .catalogCardInfo{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 20px;
        margin-bottom: 24px;
        .catalogCardTitle{
            font-size: 20px; 
            font-weight: 600; 
            @media (max-width: 768px) {
                font-size: 16px; 
            }
        }
        .catalogCardPrice{
            margin-top: 0;
            color: #848484;
            min-width: 70px;
            @media (max-width: 768px) {
                font-size: 14px; 
            }
        }
        @media (max-width: 768px) {
            flex-direction: column;
            gap: 10px;
            margin-bottom: 10px;
        }
    }
    .catalogCardArticle{
        color: #848484;
        @media (max-width: 768px) {
            font-size: 14px; 
        }
    }

    @media (max-width: 768px) {
        max-width: 173px;
    }
}